import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { getImage } from "gatsby-plugin-image";
// @ts-expect-error Not a TS file
import ToC from "../components/TOC"
import * as styles from "./BlogPost.module.css"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html, headings } = markdownRemark;
  const thumbnailImage = getImage(frontmatter.thumbnail);
  console.log("Headings", headings);
  return (
    <Layout>
            <ToC headings={headings} />
      <div className="relative w-full px-6 py-12 mx-auto md:max-w-3xl lg:max-w-4xl lg:pt-16 lg:pb-28">
        <article className="prose">
          <header className="not-prose">
              <h1 className="text-2xl font-bold">{frontmatter.title}</h1>
              <div className="italic font-medium text-small">{frontmatter.date}</div>
          </header>
          <div
              className={styles.container}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </article>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      headings {
        id
        value
        depth
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY", locale: "sv-se")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`