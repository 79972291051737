import React from 'react'

interface Props {
    children: React.ReactNode
}

const Layout = ({ children  }: Props) => (
    <>
    <header className="w-full">
      <div className="px-6 py-3 mx-auto md:max-w-4xl">
      <a href="/" className='text-xl italic text-gray-900'>herrn.se</a>
      </div>
    </header>
    <div>
      {children}
    </div>
</>
  )
  
  export default Layout