import styled from "@emotion/styled"
import React from "react"
import tw from "twin.macro"

const ToC = ({ headings }) => (
  <Toc>
    <Title>Innehållsförteckning</Title>
    <InnerScroll>
      {headings.map(heading => {
        return (
          <TocListElement key={heading.value} depth={heading.depth}>
            <ToCLink
              href={`#${heading.id}`}
            >
              {heading.value}
            </ToCLink>
          </TocListElement>
        )
      })}
    </InnerScroll>
  </Toc>
)

const Toc = styled.ul`
  ${tw`fixed flex-col hidden p-3 my-3 bg-white rounded lg:flex`};
  width: 20rem;
  left: calc(50% + 400px);
  top: 80px;
  max-height: 100vh;
`

const Title = tw.h2`text-2xl mb-2`

const depthStyles = {
    1: tw`ml-4`,
    2: tw`ml-6`,
}

const depthName = {
    1:"one",
    2: "two"
}

const classNames = {
}

const TocListElement = styled.li(({ depth }) => [
   depthStyles[depth],
    tw`p-1 leading-5  mb-4 mr-4 leading-3 list-none`
])

const ToCLink = tw.a`hover:text-black transition duration-300 no-underline`

const InnerScroll = styled.div`
  scrollbar-width: thin;
  scrollbar-color: #367ee9 rgba(48, 113, 209, 0.3);
  overflow: hidden auto;
`

export default ToC